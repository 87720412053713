<template>
	<div class="row table-active-orders">
		<div class="col-12">
			<tabla-general :data="dataTable" alto="calc(100vh - 268px)" class="cr-pointer"
				class-header="text-general f-16 f-500" sinDatos="No tienes pedidos activos">
				<template slot="cabecera-izquierda">
					<div class="col-auto f-18 text-general f-600">
						<p class="pl-3">Pedidos activos ({{ dataTable.length }})</p>
					</div>
				</template>
				<el-table-column label="Id" sortable width="100">
					<template slot-scope="{row}">
						<p class="f-16 text-black2">{{ row.id_admin_pedido }}</p>
					</template>
				</el-table-column>
				<el-table-column label="Fecha" min-width="100">
					<template slot-scope="{row}">
						<p class="f-16 text-black2">{{ row.entrega_fecha | helper-fecha('DD MMM YYYY') }}</p>
					</template>
				</el-table-column>
				<el-table-column label="Destinatario" min-width="120">
					<template slot-scope="{row}">
						<p class="f-16 text-black2">{{ row.destinatario }}</p>
					</template>
				</el-table-column>
				<el-table-column label="Dirección" min-width="200">
					<template slot-scope="{row}">
						<p class="f-16 text-black2">{{ row.direccion }}</p>
					</template>
				</el-table-column>
				<el-table-column label="Cedis" min-width="100">
					<template slot-scope="{row}">
						<p class="f-16 text-black2">{{ row.cedis }}</p>
					</template>
				</el-table-column>
				<el-table-column label="Transportadora" min-width="150">
					<template slot-scope="{row}">
						<p class="f-16 text-black2">{{ row.transportadora }}</p>
					</template>
				</el-table-column>
				<el-table-column label="No. de guía" min-width="150">
					<template slot-scope="{row}">
						<div class="">
							<div class="bg-general text-white wf-100px h-32px d-middle-center f-15 br-5" @click="openModalguias(row.guias)">
								Ver
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="Estado" min-width="150">
					<template slot-scope="{row}">
						<div :class="`br-12 pr-3 pl-2 py-1 d-middle text-black w-fit h-36px`">
							<i :class="`${statesOrdersIcons[row.estado]}`" />
							<p class="f-14 fr-medium">{{ statesOrders[row.estado] }}</p>
						</div>
					</template>
				</el-table-column>
			</tabla-general>
		</div>
		<modal-guias ref="refOpenModalGuias" />
	</div>
</template>

<script>
import Servicio from "~/services/serviciosPaqueteria";
export default {
	components: {
        modalGuias: () => import('./partials/modalGuiasEnvio.vue'),
    },
	data() {
		return {
			idServicio: parseInt(this.$route.params.id),
			dataTable: [],
			statesOrders: {
				1: 'Creado',
				2: 'Confirmado',
				201: 'Alistando',
				202: 'Empacado',
				3: 'Enviado',
			},
			statesOrdersIcons: {
				1: 'icon-shopping-outline f-20 mr-1',
				202: 'icon-shopping-outline f-20 mr-1',
				2: 'icon-ok-circled-outline f-20 mr-1',
				201: 'icon-cart-arrow-down f-18 mr-1',
				3: 'icon-truck f-20 mr-1',
			},
			statesOrdersStyles: {
				1: 'state-empacado',
				202: 'state-empacado',
				2: 'state-confirmado',
				201: 'state-alistando',
				3: 'state-enviado',
			},
		}
	},
	mounted() {
		this.getList()
	},
	methods: {
		redirect(route) {
			window.open(route, '_blank');
		},
		setState(state) {
			return this.statesOrders[state]
		},
		openModalguias(items){
			this.$refs.refOpenModalGuias.toggle(items)
		},
		async getList() {
			try {
				const { data } = await Servicio.getEnvios(this.idServicio, { estados: [1, 2, 201, 202, 3, 110, 11, 12, 13, 21, 22, 23, 24] })
				this.dataTable = data.data
			} catch (e) {
				return this.error_catch(e)
			}
		}

	},

}
</script>

<style lang="scss" scoped>
.table-active-orders {
	.w-fit {
		width: fit-content;
	}

	.state {
		&-empacado {
			background-color: #6D3CE1;
		}

		&-alistando {
			background-color: #D6017B;
		}

		&-confirmado {
			background-color: #0892DD;
		}

		&-enviado {
			background-color: #28D07B;
		}
	}
}
</style>