var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row table-active-orders"},[_c('div',{staticClass:"col-12"},[_c('tabla-general',{staticClass:"cr-pointer",attrs:{"data":_vm.dataTable,"alto":"calc(100vh - 268px)","class-header":"text-general f-16 f-500","sinDatos":"No tienes pedidos activos"}},[_c('template',{slot:"cabecera-izquierda"},[_c('div',{staticClass:"col-auto f-18 text-general f-600"},[_c('p',{staticClass:"pl-3"},[_vm._v("Pedidos activos ("+_vm._s(_vm.dataTable.length)+")")])])]),_c('el-table-column',{attrs:{"label":"Id","sortable":"","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"f-16 text-black2"},[_vm._v(_vm._s(row.id_admin_pedido))])]}}])}),_c('el-table-column',{attrs:{"label":"Fecha","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"f-16 text-black2"},[_vm._v(_vm._s(_vm._f("helper-fecha")(row.entrega_fecha,'DD MMM YYYY')))])]}}])}),_c('el-table-column',{attrs:{"label":"Destinatario","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"f-16 text-black2"},[_vm._v(_vm._s(row.destinatario))])]}}])}),_c('el-table-column',{attrs:{"label":"Dirección","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"f-16 text-black2"},[_vm._v(_vm._s(row.direccion))])]}}])}),_c('el-table-column',{attrs:{"label":"Cedis","min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"f-16 text-black2"},[_vm._v(_vm._s(row.cedis))])]}}])}),_c('el-table-column',{attrs:{"label":"Transportadora","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"f-16 text-black2"},[_vm._v(_vm._s(row.transportadora))])]}}])}),_c('el-table-column',{attrs:{"label":"No. de guía","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{},[_c('div',{staticClass:"bg-general text-white wf-100px h-32px d-middle-center f-15 br-5",on:{"click":function($event){return _vm.openModalguias(row.guias)}}},[_vm._v(" Ver ")])])]}}])}),_c('el-table-column',{attrs:{"label":"Estado","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{class:"br-12 pr-3 pl-2 py-1 d-middle text-black w-fit h-36px"},[_c('i',{class:("" + (_vm.statesOrdersIcons[row.estado]))}),_c('p',{staticClass:"f-14 fr-medium"},[_vm._v(_vm._s(_vm.statesOrders[row.estado]))])])]}}])})],2)],1),_c('modal-guias',{ref:"refOpenModalGuias"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }